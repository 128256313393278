var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[(_vm.items.length > 0)?[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('h2',[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.items.length))+" "+_vm._s(_vm.missionType)+" Customers ")])])],1)]:_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":50,"sort-by":['name']},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"cursor":"pointer","color":"blue","text-decoration":"underline"},attrs:{"to":{ name: 'Customer', params: { campaign_id: _vm.campaignId, customer_id: item.uuid } }}},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")])]}},{key:"item.sold_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(value))+" ")]}},{key:"item.ro_close_date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(value))+" ")]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(value))+" ")]}},{key:"item.number_unread_sms",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.number_sent_sms",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.phone",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(value))+" ")]}},{key:"item.term_date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(value))+" ")]}},{key:"item.opted_out_at",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(" Yes ")]:[_vm._v(" No ")]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"#FF5E40","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.showErrorDialog = false}},model:{value:(_vm.showErrorDialog),callback:function ($$v) {_vm.showErrorDialog=$$v},expression:"showErrorDialog"}},[_c('error-message',{attrs:{"error":_vm.error},on:{"closeErrorDialog":_vm.closeErrorDialog}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }