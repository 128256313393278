<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template v-if="items.length > 0">
      <v-row dense>
        <v-col>
          <h2>
            {{ formatNumber(items.length) }} {{ missionType }} Customers
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row dense>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                style="cursor: pointer; color: blue; text-decoration: underline"
                :to="{ name: 'Customer', params: { campaign_id: campaignId, customer_id: item.uuid } }"
              >
                {{ item.first_name }} {{ item.last_name }}
              </router-link>
            </template>
            <template #item.sold_at="{value}">
              {{ formatIsoDate(value) }}
            </template>
            <template #item.ro_close_date="{value}">
              {{ formatIsoDate(value) }}
            </template>    
            <template #item.created_at="{value}">
              {{ formatIsoDate(value) }}
            </template>                   
            <template #item.number_unread_sms="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #item.number_sent_sms="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #item.phone="{value}">
              {{ formatPhoneNumber(value) }}
            </template>
            <template #item.term_date="{value}">
              {{ formatIsoDate(value) }}
            </template>
            <template #item.opted_out_at="{value}">
              <template v-if="value">
                Yes
              </template>
              <template v-else>
                No
              </template>
            </template>            
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ChatovateService from '@/services/ChatovateService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'

  export default {
    name: 'ChatovateCustomers',
    components: {
      ErrorMessage,
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },
    },
    mixins: [formatterMixin, navigationMixin, titleMixin],
    data () {
      return {
        loadingMessage: 'Loading',
        showErrorDialog: false,
        campaignId: null,
        items: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        missionType: '',
        headers: [],
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadCustomers()
      },
      watch: {
      },
    },
    created () {
      this.loadCustomers()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadCustomers: function () {
        this.campaignId = (this.$route.params.campaign_id)
        this.items = []
        this.loading = true
        ChatovateService.getCustomers(this.campaignId)
          .then(response => {
            this.missionType = response.data.mission_type
            this.items = response.data.customers

            // I'm making an assumption that we will only show the same type of customers on this page. (ie, always just sales, or just service, but not a mixture)
            // Even if the same person is a sale and service customer, they will have duplicate customer records so that shouldn't be an issue.
            if (this.missionType == 'Sales') {
              this.headers = [
                { text: 'Name', value: 'name', align: 'center' },
                { text: 'Phone', value: 'phone', align: 'center' },
                { text: 'Email', value: 'email', align: 'center' },
                { text: '# Unread Messages', value: 'number_unread_sms', align: 'center' },
                { text: 'Date Sold', value: 'sold_at', align: 'center' },
                { text: 'Payments Remaining', value: 'months_remaining', align: 'center' },
                { text: 'Term Date', value: 'term_date', align: 'center' },
                { text: 'Date Imported', value: 'created_at', align: 'center' },
                { text: 'Opted Out?', value: 'opted_out_at', align: 'center' },                
                { text: 'Actions', value: 'actions', sortable: false },
              ]
            }
            if (this.missionType == 'Service') {
              this.headers = [
                { text: 'Name', value: 'name', align: 'center' },
                { text: 'Phone', value: 'phone', align: 'center' },
                { text: 'Email', value: 'email', align: 'center' },
                { text: '# Unread Messages', value: 'number_unread_sms', align: 'center' },
                { text: 'Most Recent RO Date', value: 'ro_close_date', align: 'center' },
                { text: 'Date Imported', value: 'created_at', align: 'center' },
                { text: 'Opted Out?', value: 'opted_out_at', align: 'center' },                
                { text: 'Actions', value: 'actions', sortable: false },
              ]
            }            

            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this customer?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loading = true
          ChatovateService.deleteCustomer(this.campaignId, item.uuid)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      addNewCampaign: function () {
        this.$router.push({ name: 'New Mission' })
      },
    },
  }
</script>
